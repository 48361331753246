<template>
    <div
        class="MasterAndApprentice"
        :class="{ 'pt-2': !type, 'pt-20': type }"
        @click="toDetail"
    >
        <div v-if="!type" class="jiantou">></div>
        <img
            :src="teaObj.avatar || require('@/assets/defulatAvatar.png')"
            class="avatar"
            alt=""
        />
        <div class="name one-line">{{ teaObj.name }}</div>
        <div class="tag">
            <img src="@/assets/time.png" class="timeImg" alt="" />
            <div class="time">在职工龄 {{ user.working_years || "-" }}年</div>
        </div>
        <div class="department one-line">
            {{ user.department }}
            {{ user.position ? "| " + user.position : "" }}
        </div>
        <div
            class="wantTeacher click"
            :class="{
                'back-gray': (!type && teaObj.graduation) || teaObj.select,
            }"
            @click.stop="teaObj.select ? cancelApply() : wantTeacher()"
        >
            {{
                type
                    ? teaObj.select
                        ? "取消申请"
                        : "我要拜师"
                    : teaObj.graduation
                    ? "已出师"
                    : "出师"
            }}
        </div>
    </div>
</template>

<script>
    export default {
        name: "MasterAndApprentice",
        props: {
            teaObj: {
                type: Object,
            },
            type: {
                type: Boolean,
                default: true,
            },
        },

        computed: {
            user: function () {
                return this.teaObj.department_info;
            },
        },

        methods: {
            wantTeacher() {
                this.$emit("wantTeacher", this.teaObj);
            },
            toDetail() {
                this.$emit("toDetail", this.teaObj);
            },
            cancelApply() {
                this.$emit("cancelApply", this.teaObj);
            },
        },
    };
</script>

<style lang="less" scoped>
.MasterAndApprentice {
    width: 218px;
    height: 224px;
    background: #f8f8f8;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    padding-bottom: 12px;
    padding-right: 12px;
    padding-left: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .jiantou {
        text-align: end;
        width: 100%;
    }
    .avatar {
        width: 58px;
        height: 58px;
        border-radius: 100%;
        margin-bottom: 13px;
    }
    .name {
        font-size: 14px;
        font-weight: 500;
        color: #2d2d2d;
        margin-bottom: 7px;
    }
    .tag {
        background: rgba(0, 0, 0, 0.14);
        border-radius: 11px;
        padding: 2px 9px;
        display: flex;
        align-items: center;
        .timeImg {
            width: 11px;
            height: 11px;
            margin-right: 4px;
        }
        .time {
            font-size: 12px;
            font-weight: 400;
            color: #ffffff;
        }
    }
    .department {
        font-size: 12px;
        font-weight: 400;
        color: #2d2d2d;
        margin-top: 12px;
    }
    .wantTeacher {
        width: 82px;
        height: 22px;
        background: linear-gradient(180deg, #ff3764, #ff2604);
        border-radius: 11px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 14px;
    }
    .back-gray {
        background: #cecece;
    }
}
</style>