<template>
    <div class="Apprentice">
        <div class="has-apprentice">
            <div class="top">
                <img src="@/assets/td.png" class="td-img" alt="" />
                <div class="title">我的徒弟</div>
            </div>
            <div v-if="apply_list.length || apprenticeList.length">
                <el-divider></el-divider>
                <div
                    class="apply"
                    v-for="(item, index) in apply_list"
                    :key="'apply' + index"
                >
                    <div class="flex-alc hei_100">
                        <img
                            :src="
                                item.avatar ||
                                require('@/assets/defulatAvatar.png')
                            "
                            class="avatar"
                            alt=""
                        />
                        <div class="detail">
                            <div class="title">师徒申请</div>
                            <div class="send">
                                {{ item.user }}向你发出拜师申请
                            </div>
                            <div class="desc one-line">{{ item.reason }}</div>
                        </div>
                    </div>
                    <div class="flex-jsb column flex-ale hei_100">
                        <div class="time">
                            {{ item.time.replace("-", "/") }}
                        </div>
                        <div
                            class="agree click"
                            @click="agree(item.id, item.user)"
                        >
                            同意
                        </div>
                    </div>
                </div>
                <div class="flex-alc wrap">
                    <MasterAndApprentice
                        v-for="(item, index) in apprenticeList"
                        :teaObj="item"
                        :key="'tea' + index"
                        @wantTeacher="wantTeacher"
                        class="mb-15 click"
                        @toDetail="toDetail"
                        :type="false"
                        :class="{ 'mr-15': (index + 1) % 4 != 0 }"
                    ></MasterAndApprentice>
                </div>
                <div class="flex-jsc mt-32 mb-6">
                    <Pagination
                        :pageSize="8"
                        :total="total"
                        @getPage="getPage"
                    ></Pagination>
                </div>
                <LearnTeacherSuccess
                    @close="close"
                    :type="false"
                    :relationship="true"
                    :text="text"
                    v-if="isAgree"
                ></LearnTeacherSuccess>
            </div>
        </div>
        <!-- 未收徒 -->
        <div
            class="apprentice-tip"
            v-if="!apply_list.length && !apprenticeList.length"
        >
            <div class="flex-alc column tip">
                <img
                    src="@/assets/nodata_1@2x.png"
                    class="noMasterImg"
                    alt=""
                />
                <div class="tipText">您还没徒弟呢</div>
            </div>
        </div>
    </div>
</template>

<script>
    import MasterAndApprentice from "@/components/MasterAndApprentice.vue";
    import Pagination from "@/components/Pagination.vue";
    import LearnTeacherSuccess from "@/components/LearnTeacherSuccess.vue";
    export default {
        name: "Apprentice",
        components: {
            MasterAndApprentice,
            Pagination,
            LearnTeacherSuccess,
        },
        data() {
            return {
                apply_list: [], //申请列表
                total: 0, //徒弟总数
                apprenticeList: [], //徒弟列表
                isAgree: false, //收徒成功提示
                text: "",
                page: 1, //页码
            };
        },
        async mounted() {
            window.localStorage.setItem("myNavActive", 3);
            this.$store.commit("switchMyNav", 3);
            this.getApply();
            this.getApprentice();
        },

        methods: {
            //徒弟详情
            toDetail(e) {
                this.$router.push({
                    path: "/myInfo/ApprenticeDetail",
                    query: { id: e.id },
                });
            },
            //出师
            wantTeacher(e) {
                if (e.graduation) return;
                this.$confirm("您确定要让该徒弟出师吗？", "确认信息", {
                    distinguishCancelAndClose: true,
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                })
                    .then(() => {
                        this.$https
                            .post("/api/my_pupil/graduation", { id: e.id })
                            .then((res) => {
                                if (res.errcode)
                                    return this.$message.error(res.msg);
                                this.getPage(this.page);
                            });
                    })
                    .catch((action) => {
                        return false;
                    });
            },
            //获取申请列表
            getApply() {
                this.$https.get("/api/my_pupil/apply_list").then((res) => {
                    this.apply_list = res.data;
                });
            },

            //关闭收徒成功弹窗
            close() {
                this.isAgree = false;
            },
            //分页
            getPage(e) {
                this.page = e;
                this.getApprentice(e);
            },
            //同意
            agree(e, name) {
                this.$https
                    .post("/api/my_pupil/apply_examine", {
                        id: e,
                        operation: "agree",
                    })
                    .then((res) => {
                        this.getApply();
                        if (res.errcode) return this.$message.error(res.msg);
                        this.isAgree = true;
                        this.text = `恭喜！您已成功收 ${name} 为徒`;
                        this.getApprentice();
                    });
            },
            //我的徒弟
            getApprentice(page = 1) {
                this.$https
                    .get("/api/my_pupil/list", { page, limit: 8 })
                    .then((res) => {
                        this.total = res.data.total_count;
                        this.apprenticeList = res.data.list;
                    });
            },
        },
    };
</script>

<style lang="less" scoped>
.Apprentice {
    width: 100%;
    .has-apprentice {
        background: white;
        border-radius: 6px;
        padding: 21px 24px;
        .top {
            display: flex;
            align-items: center;
            border-radius: 6px;
            .td-img {
                width: 29px;
                height: 30px;
                margin-right: 12px;
            }
            .title {
                font-size: 18px;
                font-weight: 500;
                color: #2d2d2d;
            }
        }
        .el-divider--horizontal {
            margin-top: 22px;
            margin-bottom: 18px;
            height: 2px;
        }
        .apply {
            padding: 19px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #f8f8f8;
            border: 1px solid #d5d5d5;
            border-radius: 5px;
            margin-bottom: 25px;
            height: 130px;
            .avatar {
                width: 90px;
                height: 90px;
                border-radius: 100%;
                margin-right: 19px;
            }
            .detail {
                max-width: 600px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                .title {
                    font-size: 18px;
                    font-weight: 500;
                    color: #2d2d2d;
                }
                .send {
                    font-size: 14px;
                    font-weight: 500;
                    color: #949494;
                }
                .desc {
                    font-size: 12px;
                    font-weight: 400;
                    -webkit-line-clamp: 2;
                    color: #949494;
                }
            }
            .time {
                font-size: 14px;
                font-weight: 400;
                color: #949494;
            }
            .agree {
                width: 100px;
                height: 32px;
                background: linear-gradient(180deg, #ff3764, #ff2604);
                border-radius: 17px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                font-weight: 400;
                color: #ffffff;
            }
        }
    }

    .apprentice-tip {
        background: white;
        border-radius: 7px;
        margin-top: 20px;
        .noMasterImg {
            width: 134px;
            height: 112px;
            margin-top: 66px;
        }
        .tipText {
            font-size: 16px;
            font-weight: 500;
            color: #000000;
            margin-top: 31px;
            margin-bottom: 190px;
        }
    }
}
</style>